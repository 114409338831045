import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function listStorages(params) {
  return request({
    url: ENDPOINT.LIST_STORAGE_PUBLIC,
    method: 'get',
    params
  })
}
