<template>
  <el-dialog :visible="visible" width="80%" top="50px" @close="handleClose">
    <button class="btn-close" @click="handleClose">
      <i class="fas fa-times" />
    </button>
    <div class="content p-4" v-html="data && data.content ? data.content : data.description ? data.description : data"></div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ShowContent',
  props: {
    visible: Boolean,
    data: Object
  },
  data() {
    return {
      fullscreen: false,
      options: {
        type: 'loop',
        width: '100%',
        pagination: false
      },
      images: []
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  word-break: break-word;
  font-size: 16px;
  color: #000
}
.btn-close {
  outline: none;
  font-size: 24px;
  border: none;
  background: transparent;
  float: right;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
